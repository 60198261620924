
html,
body {
  height: 100%;
  margin: 0;
}

body {
  font-family: "Montserrat", sans-serif;
  background: linear-gradient(to right, #222359, #192b4e);
  color: #ffffff;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* PageHome.css */

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.overflow-hidden {
  overflow: hidden;
}

.object-cover {
  object-fit: cover;
}

.w-full {
  width: 100%;
}

.h-screen {
  height: 100vh;
}

.bg-black\/50 {
  background-color: rgba(0, 0, 0, 0.5);
}

.animate-fade-in {
  animation: fadeIn 3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* styles.css */
.phone-frame {
  position: relative;
  width: 330px; /* Adjust size as needed */
  height: 640px; /* Adjust size as needed */
  border: 16px solid #161616; /* Dark border for the phone */
  border-radius: 36px; /* Rounded corners */
  overflow: hidden;
  background: #000; /* Black background for the phone */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); /* Subtle shadow for realism */
}

.phone-frame:before {
  content: '';
  position: absolute;
  top: 20px;
  left: 50%;
  width: 60px;
  height: 5px;
  background: #666; /* Subtle color for the notch */
  border-radius: 10px;
  transform: translateX(-50%);
}

.phone-frame:after {
  content: '';
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 70px;
  height: 5px;
  background: #292929; /* Subtle color for the bottom button */
  border-radius: 10px;
  transform: translateX(-50%);
}

.phone-screen {
  position: absolute;
  top: 14px; /* Adjusted to fit inside the phone frame */
  left: 14px; /* Adjusted to fit inside the phone frame */
  right: 14px; /* Adjusted to fit inside the phone frame */
  bottom: 14px; /* Adjusted to fit inside the phone frame */
  border-radius: 30px; /* Rounded corners for the screen */
  background: #000; /* Black screen */
  overflow: hidden;
}

.slider-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure images cover the screen area */
}

.scroll-arrow {
  /* Ensures the scroll arrow is positioned correctly and styled */
}
