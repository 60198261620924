@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Bungee&family=Orbitron:wght@500&family=Russo+One&display=swap');
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
  background: linear-gradient(to right, #222359, #192b4e);
  color: #ffffff;
}


